import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { logout } from './user';
import { deleteOneAccount } from './account-details';
import { createTransfer, deleteOneRow } from './transfer';

export const fetchAccounts = createAsyncThunk(
  'accountList/getAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/api/accounts`, { withCredentials: true });
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg, name });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const createOne = createAsyncThunk(
  'accountList/createOne',
  async ({ name, description }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/accounts`, { name, description });
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg, name });
      }

      return rejectWithValue({ error: 'An unexpected error occured', name });
    }
  }
);

const accountListInitialState = {
  accountList: {
    accounts: [],
    accountListForSelect: [{ value: 0, label: 'None' }],
    loading: false,
    error: null,
    done: false,
  },
  newAccount: {
    loading: false,
    account: {},
    error: null,
    done: false,
  },
};

export const accountListSlice = createSlice({
  name: 'accountList',
  initialState: { ...accountListInitialState },
  reducers: {
    clearNewAccount: (state) => {
      return {
        ...state,
        newAccount: {
          ...accountListInitialState.newAccount,
        },
      };
    },
    clearAccounts: () => {
      return {
        ...accountListInitialState,
      };
    },
    updateNewAccount: (state, action) => {
      const { account } = action.payload;
      return {
        ...state,
        accountList: {
          ...state.accountList,
          accounts: state.accountList.accounts.map((a) => (a.id === account.id ? account : a)),
          accountListForSelect: state.accountList.accountListForSelect.map((a) =>
            a.value === account.id ? { ...a, label: account.name } : { ...a }
          ),
        },
      };
    },
    updateBudgetOnAccounts: (state, action) => {
      // const { budget } = action.payload;
      // return {
      //   ...state,
      //   accountList: {
      //     ...state.accountList,
      //     accounts: state.accountList.accounts.map((a) =>
      //       a.id === budget.account_id
      //         ? {
      //             ...a,
      //             budgets: a.budgets.map((b) =>
      //               b.id === budget.id ? { ...b, name: budget.name } : b
      //             ),
      //           }
      //         : a
      //     ),
      //   },
      // };
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        loading: return {
          ...state,
          accountList: {
            ...accountListInitialState.accountList,
            loading: true,
          },
        };
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        const { accounts } = action.payload;
        return {
          ...state,
          accountList: {
            ...accountListInitialState.accountList,
            accounts,
            accountListForSelect: state.accountList.accountListForSelect.concat(
              accounts.map((a) => ({ value: a.id, label: a.name }))
            ),
            done: true,
          },
        };
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        return {
          ...state,
          accountList: {
            ...accountListInitialState.accountList,
            error: action.payload.error,
            done: true,
          },
        };
      })
      .addCase(createOne.pending, (state) => {
        loading: return {
          ...state,
          newAccount: {
            ...accountListInitialState.newAccount,
            loading: true,
          },
        };
      })
      .addCase(createOne.fulfilled, (state, action) => {
        const newAccount = action.payload.account;
        return {
          ...state,
          accountList: {
            ...state.accountList,
            accounts: state.accountList.accounts.concat(newAccount),
            accountListForSelect: state.accountList.accountListForSelect.concat({
              value: newAccount.id,
              label: newAccount.name,
            }),
          },
          newAccount: {
            ...accountListInitialState.newAccount,
            account: newAccount,
            done: true,
          },
        };
      })
      .addCase(createOne.rejected, (state, action) => {
        return {
          ...state,
          newAccount: {
            ...accountListInitialState.newAccount,
            account: { name: action.payload.name },
            done: true,
            error: action.payload.error,
          },
        };
      })
      .addCase(logout.fulfilled, () => {
        return { ...accountListInitialState };
      })
      .addCase(deleteOneAccount.fulfilled, (state, action) => {
        const { id } = action.payload;
        return {
          ...accountListInitialState,
          accountList: {
            ...state.accountList,
            accounts: state.accountList.accounts.filter(a => a.id !== id),
            accountListForSelect: state.accountList.accountListForSelect
              .filter(a => a.value !== id),
          },
        };
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        const { transfer } = action.payload;

        const withdrawalAccount = state.accountList.accounts.find(a => a.id === transfer.withdrawal_account_id);
        const depositAccount = state.accountList.accounts.find(a => a.id === transfer.deposit_account_id);

        return {
          ...state,
          accountList: {
            ...state.accountList,
            accounts: state.accountList.accounts.map(a => {
              if (!!withdrawalAccount && a.id === withdrawalAccount.id) {
                return { ...a, balance: a.balance - transfer.amount };
              } else if (!!depositAccount && a.id === depositAccount.id) {
                return { ...a, balance: a.balance + transfer.amount };
              } else {
                return { ...a };
              }
            })
          }
        }
      })
      .addCase(deleteOneRow.fulfilled, (state, action) => {
        const { transfer } = action.payload;

        const withdrawalAccount = state.accountList.accounts.find(a => a.id === transfer.withdrawal_account_id);
        const depositAccount = state.accountList.accounts.find(a => a.id === transfer.deposit_account_id);

        return {
          ...state,
          accountList: {
            ...state.accountList,
            accounts: state.accountList.accounts.map(a => {
              if (!!withdrawalAccount && a.id === withdrawalAccount.id) {
                return { ...a, balance: a.balance + transfer.amount };
              } else if (!!depositAccount && a.id === depositAccount.id) {
                return { ...a, balance: a.balance - transfer.amount };
              } else {
                return { ...a };
              }
            })
          }
        }
      });
  },
});

export const { clearNewAccount, clearAccounts, updateNewAccount, updateBudgetOnAccounts } =
  accountListSlice.actions;

export default accountListSlice.reducer;
