import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { updateMonthlyNeeds } from './account-details';
import { logout } from './user';

function computeMonthlyNeed(incomesRows, outcomesRows) {
  const today = (new Date()).getDate();

  const inSum = incomesRows
    .filter(r => r.day_number >= today)
    .reduce((acc, cur) => acc + cur.amount, 0);

  const outSum = outcomesRows
    .filter(r => r.day_number >= today)
    .reduce((acc, cur) => acc + cur.amount, 0);

  const total = outSum - inSum;

  return total < 0 ? 0 : total;
}

export const fetchAccountRecurrentTransfers = createAsyncThunk(
  'recurrentTransfers/getAll',
  async (accountId, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/api/recurrent-transfers?account_id=${accountId}`);
      return {
        incomes: response.data.incomes,
        outcomes: response.data.outcomes,
        account_id: accountId,
      };
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const createRecurrentTransferRow = createAsyncThunk(
  'recurrentTransfer/createRecurrentTransferRow',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/recurrent-transfers`, { ...payload });
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg, name });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const deleteRecurrentTransfer = createAsyncThunk(
  'recurrentTransfer/deleteRecurrentTransfer',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      await axios.delete(`/api/recurrent-transfers/${id}`);

      const incomesTransfers = getState().recurrentTransfers.list.incomes.filter(t => t.id !== id);
      const outcomesTransfers = getState().recurrentTransfers.list.expenses.filter(t => t.id !== id);
      dispatch(updateMonthlyNeeds({ amount_needed: computeMonthlyNeed(incomesTransfers, outcomesTransfers) }));

      return { id };
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg, name });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

const recurrentTransfersInitialState = {
  list: {
    account_id: null,
    expenses: [],
    incomes: [],
    loading: false,
    error: null,
    done: false,
  },
  create: {
    loading: false,
    error: null,
    done: false,
    mode: 1, // 1 for deposit, 2 for expense
  },
  delete: {
    loading: false,
    error: null,
    done: false,
    mode: 1, // 1 for deposit, 2 for expense
  },
};

function sortRecurrentTransfers(arr) {
  return []
    .concat(arr)
    .filter(Boolean)
    .sort((a, b) => (a.day_number >= b.day_number ? 1 : -1));
}

export const recurrentTransfersSlices = createSlice({
  name: 'recurrentTransfers',
  initialState: { ...recurrentTransfersInitialState },
  reducers: {
    clearRecurrentTransfers: () => {
      return {
        ...recurrentTransfersInitialState,
      };
    },
    clearNewRecurrentTransfer: (state) => {
      return {
        ...state,
        create: {
          ...recurrentTransfersInitialState.create,
        },
      };
    },
    clearDeleteRecurrentTransfer: (state) => {
      return {
        ...state,
        delete: {
          ...recurrentTransfersInitialState.delete,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRecurrentTransferRow.pending, (state) => {
        return {
          ...state,
          create: {
            ...recurrentTransfersInitialState.create,
            loading: true,
          },
        };
      })
      .addCase(createRecurrentTransferRow.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          create: {
            ...recurrentTransfersInitialState.create,
            done: true,
            error,
          },
        };
      })
      .addCase(createRecurrentTransferRow.fulfilled, (state, action) => {
        const { recurrent_transfer } = action.payload;
        return {
          ...state,
          create: {
            ...state.create,
            mode: recurrent_transfer.withdrawal_account_id ? 2 : 1,
          },
          list: {
            ...state.list,
            expenses:
              recurrent_transfer.withdrawal_account_id === state.list.account_id
                ? sortRecurrentTransfers(state.list.expenses.concat(recurrent_transfer))
                : state.list.expenses,
            incomes:
              recurrent_transfer.deposit_account_id === state.list.account_id
                ? sortRecurrentTransfers(state.list.incomes.concat(recurrent_transfer))
                : state.list.incomes,
          },
          create: {
            ...recurrentTransfersInitialState.create,
            done: true,
          },
        };
      })
      .addCase(fetchAccountRecurrentTransfers.pending, (state) => {
        return {
          ...state,
          list: {
            ...recurrentTransfersInitialState.list,
            loading: true,
          },
        };
      })
      .addCase(fetchAccountRecurrentTransfers.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          list: {
            ...recurrentTransfersInitialState.list,
            done: true,
            error,
          },
        };
      })
      .addCase(fetchAccountRecurrentTransfers.fulfilled, (state, action) => {
        const { outcomes, incomes, account_id } = action.payload;
        return {
          ...state,
          list: {
            ...recurrentTransfersInitialState.list,
            done: true,
            account_id,
            expenses: sortRecurrentTransfers(outcomes),
            incomes: sortRecurrentTransfers(incomes),
          },
        };
      })
      .addCase(deleteRecurrentTransfer.pending, (state) => {
        return {
          ...state,
          delete: {
            ...recurrentTransfersInitialState.delete,
            loading: true,
          },
        };
      })
      .addCase(deleteRecurrentTransfer.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          delete: {
            ...recurrentTransfersInitialState.delete,
            done: true,
            error,
          },
        };
      })
      .addCase(deleteRecurrentTransfer.fulfilled, (state, action) => {
        const { id } = action.payload;
        const isExpense = !!state.list.expenses.find((e) => e.id === id);
        return {
          ...state,
          delete: {
            ...recurrentTransfersInitialState.delete,
            done: true,
            mode: isExpense ? 2 : 1,
          },
          list: {
            ...state.list,
            expenses: sortRecurrentTransfers(state.list.expenses.filter((e) => e.id !== id)),
            incomes: sortRecurrentTransfers(state.list.incomes.filter((e) => e.id !== id)),
          },
        };
      });
  },
});

export const {
  clearRecurrentTransfers,
  clearNewRecurrentTransfer,
  clearDeleteRecurrentTransfer } = recurrentTransfersSlices.actions;

export default recurrentTransfersSlices.reducer;
