import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { updateNewAccount } from './account-list';
import { logout } from './user';
import { createTransfer, deleteOneRow } from './transfer';
import { createRecurrentTransferRow } from './recurrent-transfers';

export const fetchOneAccount = createAsyncThunk(
  'accountDetails/getOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/api/accounts/${id}`);
      if (!response.data || !response.data.account || !Object.keys(response.data.account).length) {
        return rejectWithValue({ error: 'Account not found', id });
      }

      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const fetchAccountBudgets = createAsyncThunk(
  'accountDetails/fetchAccountBudgets',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const queryParams = [];
      const month = getState().accountDetails.oneAccount.budget_search.month;
      const year = getState().accountDetails.oneAccount.budget_search.year;
      if (!!year) {
        queryParams.push(`year=${year}`);
      }

      if (!!month) {
        queryParams.push(`month=${month}`);
      }

      const qs = !!queryParams.length ? `?${queryParams.join('&')}` : '';
      const response = await axios.get(`/api/accounts/${id}/budgets${qs}`);
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const updateOneAccount = createAsyncThunk(
  'accountDetails/updateOneAccount',
  async ({ id, name, description }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`/api/accounts/${id}`, { name, description });
      if (!response.data || !response.data.account || !Object.keys(response.data.account).length) {
        return rejectWithValue({ error: 'Account not found', id });
      }

      dispatch(updateNewAccount(response.data));

      return response.data;
    } catch (err) {
      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const deleteOneAccount = createAsyncThunk(
  'accountDetails/deleteOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`/api/accounts/${id}`);
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

const accountDetailsInitialState = {
  oneAccount: {
    account: {},
    budgets: [],
    transfers: [],
    budget_search: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      reload: false,
    },
    amount_needed: -1,
    loading: false,
    done: false,
    error: null,
  },
  deleteOne: {
    done: false,
    loading: false,
    error: null,
  },
  updateOne: {
    done: false,
    loading: false,
    error: null,
    account: {},
  },
};

export const accountDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState: { ...accountDetailsInitialState },
  reducers: {
    clearAccountDetailsState: () => {
      return { ...accountDetailsInitialState };
    },
    clearDeleteOneState: (state) => {
      return {
        ...state,
        deleteOne: {
          ...accountDetailsInitialState.deleteOne,
        },
      };
    },
    setSearchParamsForBudget: (state, action) => {
      const { month, year } = action.payload;
      return {
        ...state,
        oneAccount: {
          ...state.oneAccount,
          budget_search: {
            year,
            month,
            reload: true,
          },
        },
      };
    },
    clearUpdateOneState: (state) => {
      return {
        ...state,
        updateOne: {
          ...accountDetailsInitialState.updateOne,
        },
      };
    },
    updateMonthlyNeeds: (state, action) => {
      const { amount_needed } = action.payload;
      return {
        ...state,
        oneAccount: {
          ...state.oneAccount,
          amount_needed
        },
      };
    },
    increaseBalance: (state, action) => {
      const { amount } = action.payload;
      return {
        ...state,
        oneAccountState: {
          ...state.oneAccountState,
          account: {
            ...state.oneAccountState.account,
            balance: Number(state.oneAccountState.account.balance) + Number(amount)
          }
        }
      }
    },
    decreaseBalance: (state, action) => {
      const { amount } = action.payload;
      return {
        ...state,
        oneAccountState: {
          ...state.oneAccountState,
          account: {
            ...state.oneAccountState.account,
            balance: Number(state.oneAccountState.account.balance) - Number(amount)
          }
        }
      }
    },
    updateBudgetOnAccount: (state, action) => {
      // const { budget } = action.payload;
      // if (!budget || !budget.account_id || budget.account_id !== state.oneAccount.account.id) {
      //   return { ...state };
      // }

      // return {
      //   ...state,
      //   oneAccount: {
      //     ...state.oneAccount,
      //     budgets: state.oneAccount.budgets
      //       .map(b => (b.id === budget.id ? budget : b))
      //   }
      // };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneAccount.pending, (state) => {
        loading: return {
          ...state,
          oneAccount: {
            ...accountDetailsInitialState.oneAccount,
            loading: true,
          },
        };
      })
      .addCase(fetchOneAccount.fulfilled, (state, action) => {
        const { account, amount_needed } = action.payload;
        return {
          ...state,
          oneAccount: {
            ...accountDetailsInitialState.oneAccount,
            account: {
              ...account,
            },
            amount_needed,
            done: true,
          },
        };
      })
      .addCase(fetchOneAccount.rejected, (state, action) => {
        const { id, error } = action.payload;
        return {
          ...state,
          oneAccount: {
            ...accountDetailsInitialState.oneAccount,
            account: { id },
            error,
            done: true,
          },
        };
      })
      .addCase(deleteOneAccount.pending, (state) => {
        loading: return {
          ...state,
          deleteOne: {
            ...accountDetailsInitialState.deleteOne,
            loading: true,
          },
        };
      })
      .addCase(deleteOneAccount.fulfilled, (state) => {
        return {
          ...state,
          deleteOne: {
            ...accountDetailsInitialState.deleteOne,
            done: true,
          },
        };
      })
      .addCase(deleteOneAccount.rejected, (state, action) => {
        return {
          ...state,
          deleteOne: {
            ...accountDetailsInitialState.deleteOne,
            done: true,
            error: action.payload.error,
          },
        };
      })
      .addCase(updateOneAccount.pending, (state) => {
        loading: return {
          ...state,
          deleteOne: {
            ...accountDetailsInitialState.updateOne,
            loading: true,
          },
        };
      })
      .addCase(updateOneAccount.fulfilled, (state, action) => {
        const { account } = action.payload;
        return {
          ...state,
          updateOne: {
            ...accountDetailsInitialState.updateOne,
            done: true,
            account,
          },
          oneAccount: {
            ...state.oneAccount,
            ...(state.oneAccount.account.id === account.id ? { account } : {}),
          },
        };
      })
      .addCase(updateOneAccount.rejected, (state, action) => {
        return {
          ...state,
          updateOne: {
            ...accountDetailsInitialState.updateOne,
            done: true,
            error: action.payload.error,
          },
        };
      })
      .addCase(logout.fulfilled, () => {
        return { ...accountDetailsInitialState };
      })
      .addCase(fetchAccountBudgets.pending, (state) => {
        return {
          ...state,
          oneAccount: {
            ...state.oneAccount,
            budget_search: {
              ...state.oneAccount.budget_search,
              reload: false,
            },
          }
        };
      })
      .addCase(fetchAccountBudgets.rejected, (state) => {
        return {
          ...state,
          oneAccount: {
            ...state.oneAccount,
            budget_search: {
              ...state.oneAccount.budget_search,
              reload: false,
            },
          }
        };
      })
      .addCase(fetchAccountBudgets.fulfilled, (state, action) => {
        const { budgets } = action.payload;
        return {
          ...state,
          oneAccount: {
            ...state.oneAccount,
            budgets,
            budget_search: {
              ...state.oneAccount.budget_search,
              reload: false,
            },
          },
        };
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        if (!state.oneAccount.account.id) {
          return { ...state };
        }

        const { transfer } = action.payload;

        const accountId = state.oneAccount.account.id;
        const sumToAdd = transfer.withdrawal_account_id === accountId
          ? -transfer.amount
          : transfer.deposit_account_id === accountId
            ? transfer.amount
            : 0;

        return {
          ...state,
          oneAccount: {
            ...state.oneAccount,
            account: {
              ...state.oneAccount.account,
              balance: Number(state.oneAccount.account.balance) + Number(sumToAdd)
            }
          }
        }
      })
      .addCase(deleteOneRow.fulfilled, (state, action) => {
        if (!state.oneAccount.account.id) {
          return { ...state };
        }

        const { transfer } = action.payload;

        const accountId = state.oneAccount.account.id;
        const sumToSub = transfer.withdrawal_account_id === accountId
          ? -transfer.amount
          : transfer.deposit_account_id === accountId
            ? transfer.amount
            : 0;

        return {
          ...state,
          oneAccount: {
            ...state.oneAccount,
            account: {
              ...state.oneAccount.account,
              balance: Number(state.oneAccount.account.balance) - Number(sumToSub)
            }
          }
        }
      })
      .addCase(createRecurrentTransferRow.fulfilled, (state, action) => {
        if (!state.oneAccount.account.id) {
          return { ...state };
        }

        const { recurrent_transfer } = action.payload;
        const today = (new Date()).getDate();
        if (!recurrent_transfer || recurrent_transfer.day_number < today) {
          return { ...state };
        }

        let currentAmountNeeded = state.oneAccount.amount_needed;
        if (recurrent_transfer.withdrawal_account_id === state.oneAccount.account.id) {
          currentAmountNeeded += recurrent_transfer.amount;
        }

        if (recurrent_transfer.deposit_account_id === state.oneAccount.account.id) {
          currentAmountNeeded -= recurrent_transfer.amount;
        }

        if (currentAmountNeeded < 0) {
          currentAmountNeeded = 0;
        }

        // If no change
        if (currentAmountNeeded === state.oneAccount.amount_needed) {
          return { ...state };
        }

        return {
          ...state,
          oneAccount: {
            ...state.oneAccount,
            amount_needed: currentAmountNeeded
          },
        };

      });
  },
});

export const {
  clearAccountDetailsState,
  clearDeleteOneState,
  clearUpdateOneState,
  updateBudgetOnAccount,
  setSearchParamsForBudget,
  updateMonthlyNeeds,
  increaseBalance,
  decreaseBalance
} = accountDetailsSlice.actions;

export default accountDetailsSlice.reducer;
