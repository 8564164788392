import transfer from './transfer';
import navbar from './navbar';
import layout from './layout';
import accountList from './account-list';
import accountDetails from './account-details';
import budgetList from './budget-list';
import budgetDetails from './budget-details';
import user from './user';
import recurrentTransfers from './recurrent-transfers';

const rootReducer = {
  transfer,
  accountList,
  accountDetails,
  budgetList,
  budgetDetails,
  navbar,
  layout,
  user,
  recurrentTransfers
};

export default rootReducer;
