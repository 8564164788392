import '@styles/react/libs/toastify/toastify.scss'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'

import './@core/components/ripple-button'
import Spinner from './@core/components/spinner/Fallback-spinner'

import axios from 'axios';
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import { store } from './redux/store'
import { ThemeContext } from './utility/context/ThemeColors'

import './configs/i18n'

import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

import * as serviceWorker from './serviceWorker'

const LazyApp = lazy(() => import('./App'))

axios.defaults.withCredentials = true;
delete axios.defaults.headers.common['X-Requested-With']

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
