import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { deleteBudget } from './budget-list';
import { logout } from './user';
import { createTransfer, deleteOneRow } from './transfer';

export const fetchOneBudget = createAsyncThunk(
  'budgetDetails/fetchOne',
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const year = getState().budgetDetails.oneBudget.currentYear;
      const response = await axios.get(`/api/budgets/${id}?year=${year}`);
      if (!response.data || !response.data.budget || !Object.keys(response.data.budget).length) {
        return rejectWithValue({ error: 'Budget not found', id });
      }

      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const deleteOneBudget = createAsyncThunk(
  'budgetDetails/deleteOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`/api/budgets/${id}`);
      dispatch(deleteBudget({ id }));
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const updateOneBudget = createAsyncThunk(
  'budgetDetails/updateOne',
  async ({ id, name, description }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`/api/budgets/${id}`, { name, description });
      if (response.data.budget) {
        // dispatch(updateBudgetOnAccount(response.data));
        // dispatch(updateBudgetOnAccounts(response.data));
      }
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

const budgetDetailsInitialState = {
  oneBudget: {
    budget: {},
    yearly_moves: [],
    done: false,
    loading: false,
    error: null,
    currentYear: new Date().getFullYear(),
  },
  deleteOne: {
    done: false,
    loading: false,
    error: null,
  },
  updateOne: {
    done: false,
    loading: false,
    budget: {},
    error: null,
  },
};

export const budgetDetailsSlice = createSlice({
  name: 'budgetDetails',
  initialState: { ...budgetDetailsInitialState },
  reducers: {
    clearBudgetDetailsState: () => {
      return {
        ...budgetDetailsInitialState,
      };
    },
    updateBudgetYear: (state, action) => {
      return {
        ...state,
        oneBudget: {
          ...budgetDetailsInitialState.oneBudget,
          currentYear: action.payload,
        },
      };
    },
    clearDeleteOneState: (state) => {
      return {
        ...state,
        deleteOne: {
          ...budgetDetailsInitialState.deleteOne,
        },
      };
    },
    clearUpdateOneState: (state) => {
      return {
        ...state,
        updateOne: {
          ...budgetDetailsInitialState.updateOne,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneBudget.pending, (state) => {
        loading: return {
          ...budgetDetailsInitialState,
          oneBudget: {
            ...budgetDetailsInitialState.oneBudget,
            loading: true,
            currentYear: state.oneBudget.currentYear,
          },
        };
      })
      .addCase(fetchOneBudget.fulfilled, (state, action) => {
        const { budget } = action.payload;
        return {
          ...state,
          oneBudget: {
            ...budgetDetailsInitialState.oneBudget,
            budget: { ...budget, yearly_moves: undefined },
            yearly_moves: budget.yearly_moves,
            currentYear: budget.yearly_moves[0].year,
            done: true,
          },
        };
      })
      .addCase(fetchOneBudget.rejected, (state, action) => {
        const { id, error } = action.payload;
        return {
          ...state,
          oneBudget: {
            ...budgetDetailsInitialState.oneBudget,
            budget: { id },
            error,
            done: true,
            currentYear: state.oneBudget.currentYear,
          },
        };
      })
      .addCase(deleteOneBudget.pending, (state) => {
        loading: return {
          ...state,
          deleteOne: {
            ...budgetDetailsInitialState.deleteOne,
            loading: true,
          },
        };
      })
      .addCase(deleteOneBudget.fulfilled, (state) => {
        return {
          ...state,
          deleteOne: {
            ...budgetDetailsInitialState.deleteOne,
            done: true,
          },
        };
      })
      .addCase(deleteOneBudget.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          deleteOne: {
            ...budgetDetailsInitialState.deleteOne,
            done: true,
            error,
          },
        };
      })
      .addCase(updateOneBudget.pending, (state, action) => {
        loading: return {
          ...state,
          updateOne: {
            ...budgetDetailsInitialState.updateOne,
            loading: true,
          },
        };
      })
      .addCase(updateOneBudget.fulfilled, (state, action) => {
        const { budget } = action.payload;
        return {
          ...state,
          updateOne: {
            ...budgetDetailsInitialState.updateOne,
            done: true,
            budget,
          },
          oneBudget: {
            ...state.oneBudget,
            ...(state.oneBudget.budget.id === budget.id
              ? { budget: Object.assign({}, state.oneBudget.budget, budget) }
              : {}),
          },
        };
      })
      .addCase(updateOneBudget.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          updateOne: {
            ...budgetDetailsInitialState.updateOne,
            done: true,
            error,
          },
        };
      })
      .addCase(logout.fulfilled, () => {
        return { ...budgetDetailsInitialState };
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        if (!state.oneBudget.budget.id) {
          return { ...state };
        }

        const { transfer } = action.payload;
        
        const budgetName = state.oneBudget.budget.name;
        const sumToAdd = transfer.withdrawal_budgets.includes(budgetName)
          ? -transfer.amount
          : transfer.deposit_budgets.includes(budgetName)
            ? transfer.amount
            : 0;

        return {
          ...state,
          oneBudget: {
            ...state.oneBudget,
            budget: {
              ...state.oneBudget.budget,
              balance: state.oneBudget.budget.balance + sumToAdd
            }
          }
        }
      })
      .addCase(deleteOneRow.fulfilled, (state, action) => {
        if (!state.oneBudget.budget.id) {
          return { ...state };
        }

        const { transfer } = action.payload;

        const budgetName = state.oneBudget.budget.name;
        const sumToSub = transfer.withdrawal_budgets.includes(budgetName)
          ? -transfer.amount
          : transfer.deposit_budgets.includes(budgetName)
            ? transfer.amount
            : 0;

        return {
          ...state,
          oneBudget: {
            ...state.oneBudget,
            budget: {
              ...state.oneBudget.budget,
              balance: state.oneBudget.budget.balance - sumToSub
            }
          }
        }
      });
  },
});

export const {
  clearBudgetDetailsState,
  clearDeleteOneState,
  clearUpdateOneState,
  updateBudgetYear,
} = budgetDetailsSlice.actions;

export default budgetDetailsSlice.reducer;
