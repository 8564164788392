import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

import { logout } from './user';
import { createTransfer, deleteOneRow } from './transfer';

export const fetchBudgets = createAsyncThunk(
  'budgetList/getAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/api/budgets`);
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const createOneBudget = createAsyncThunk(
  'budgetList/createOneBudget',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/budgets`, { ...payload });
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

const budgetListInitialState = {
  budgetList: {
    budgets: [],
    budgetListForSelect: [],
    loading: false,
    error: null,
    done: false,
  },
  newBudget: {
    loading: false,
    budget: {},
    error: null,
    done: false,
  },
};

export const budgetListSlice = createSlice({
  name: 'budgetList',
  initialState: { ...budgetListInitialState },
  reducers: {
    clearNewBudget: (state) => {
      return {
        ...state,
        newBudget: {
          ...budgetListInitialState.newBudget,
        },
      };
    },
    clearBudgets: () => {
      return {
        ...budgetListInitialState,
      };
    },
    deleteBudget: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        budgetList: {
          ...state.budgetList,
          budgets: state.budgetList.budgets.filter((b) => b.id !== id),
          budgetListForSelect: state.budgetList.budgetListForSelect.filter((b) => b.value !== id),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgets.pending, (state) => {
        return {
          ...state,
          budgetList: {
            ...budgetListInitialState.budgetList,
            loading: true,
          },
        };
      })
      .addCase(fetchBudgets.fulfilled, (state, action) => {
        const { budgets } = action.payload;
        return {
          ...state,
          budgetList: {
            ...budgetListInitialState.budgetList,
            budgets,
            budgetListForSelect: [].concat(budgets.map((b) => ({ value: b.id, label: b.name }))),
            done: true,
          },
        };
      })
      .addCase(fetchBudgets.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          budgetList: {
            ...budgetListInitialState.budgetList,
            done: true,
            error,
          },
        };
      })
      .addCase(createOneBudget.pending, (state) => {
        return {
          ...state,
          newBudget: {
            ...budgetListInitialState.newBudget,
            loading: true,
          },
        };
      })
      .addCase(createOneBudget.fulfilled, (state, action) => {
        const { budget } = action.payload;
        return {
          ...state,
          budgetList: {
            ...state.budgetList,
            budgets: state.budgetList.budgets.concat(budget),
            budgetListForSelect: state.budgetList.budgetListForSelect.concat({
              value: budget.id,
              label: budget.label,
            }),
          },
          newBudget: {
            ...budgetListInitialState.newBudget,
            budget,
            done: true,
          },
        };
      })
      .addCase(createOneBudget.rejected, (state, action) => {
        const { name, error } = action.payload;
        return {
          ...state,
          newBudget: {
            ...budgetListInitialState.newBudget,
            budget: { name },
            done: true,
            error,
          },
        };
      })
      .addCase(logout.fulfilled, () => {
        return { ...budgetListInitialState };
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        const { transfer } = action.payload;

        const withdrawalBudgetsIds = state.budgetList.budgets
          .filter(b => transfer.withdrawal_budgets.includes(b.name))
          .map(b => b.id);
        const depositBudgetsIds = state.budgetList.budgets
          .filter(b => transfer.deposit_budgets.includes(b.name))
          .map(b => b.id);

        return {
          ...state,
          budgetList: {
            ...state.budgetList,
            budgets: state.budgetList.budgets.map(b => {
              if (!!withdrawalBudgetsIds && !!withdrawalBudgetsIds.length && withdrawalBudgetsIds.includes(b.id)) {
                return { ...b, balance: b.balance - transfer.amount };
              } else if (!!depositBudgetsIds && !!depositBudgetsIds.length && depositBudgetsIds.includes(b.id)) {
                return { ...b, balance: b.balance + transfer.amount };
              } else {
                return { ...b };
              }
            })
          }
        }
      })
      .addCase(deleteOneRow.fulfilled, (state, action) => {
        const { transfer } = action.payload;

        const withdrawalBudgetsIds = state.budgetList.budgets
          .filter(b => transfer.withdrawal_budgets.includes(b.name))
          .map(b => b.id);
        const depositBudgetsIds = state.budgetList.budgets
          .filter(b => transfer.deposit_budgets.includes(b.name))
          .map(b => b.id);

        return {
            ...state,
            budgetList: {
              ...state.budgetList,
              budgets: state.budgetList.budgets.map(b => {
                if (!!withdrawalBudgetsIds && !!withdrawalBudgetsIds.length && withdrawalBudgetsIds.includes(b.id)) {
                  return { ...b, balance: b.balance + transfer.amount };
                } else if (!!depositBudgetsIds && !!depositBudgetsIds.length && depositBudgetsIds.includes(b.id)) {
                  return { ...b, balance: b.balance - transfer.amount };
                } else {
                  return { ...b };
                }
              })
            }
          }
      });
  },
});

export const { clearNewBudget, clearBudgets, deleteBudget } = budgetListSlice.actions;

export default budgetListSlice.reducer;
