import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

const LOCAL_STORAGE_USER_KEY = 'userData';

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/auth/signout`);
      return true;
    } catch (err) {
      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg, name });
      }

      return true;
    }
  }
);

const logoutAction = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  return { userData: { id: -1 } };
};

const initialUser = () => {
  const item = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);
  if (!!item) {
    try {
      return JSON.parse(item);
    } catch (err) {}
  }

  return {};
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    setUserDataAndSave: (state, action) => {
      state.userData = action.payload;
      window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(state.userData));
    },
    clearUserDataAndSave: state => {
      state.userData = {};
      window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    },
    initUser: state => {
      const localStorageUser = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);
      if (!!localStorageUser) {
        try {
          const userData = JSON.parse(localStorageUser);
          return { userData };
        } catch (err) {}
      }

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout.fulfilled, () => {
        return logoutAction();
      })
      .addCase(logout.rejected, () => {
        return logoutAction();
      });
    }
});

export const {
    setUserDataAndSave,
    clearUserDataAndSave,
    initUser,
} = userSlice.actions;

export default userSlice.reducer;
