import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { logout } from './user';

export const fetchRows = createAsyncThunk(
  'transfer/fetchRows',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const searchParams = getState().transfer.searchParams;

      let queryParams = [];

      if (!!searchParams.account_id) {
        queryParams = queryParams.concat(`account_id=${searchParams.account_id}`);
      } else if (!!searchParams.budget_id) {
        queryParams = queryParams.concat(`budget_id=${searchParams.budget_id}`);
      }

      if (!!searchParams.budgets && !!searchParams.budgets.length) {
        queryParams = queryParams.concat(`budgets=${searchParams.budgets.join(',')}`);
      }

      if (!!searchParams.month) {
        queryParams = queryParams.concat(`month=${searchParams.month}`);
      }

      if (searchParams.year) {
        queryParams = queryParams.concat(`year=${searchParams.year}`);
      }

      const response = await axios.get(`/api/transfers?${queryParams.join('&')}`);
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const createTransfer = createAsyncThunk(
  'transfer/createTransfer',
  async (
    {
      withdrawal_account_id,
      withdrawal_budgets,
      withdrawal_description,
      deposit_account_id,
      deposit_budgets,
      deposit_description,
      amount,
      record_date,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const payload = {
        ...(withdrawal_account_id ? { withdrawal_account_id } : {}),
        ...(withdrawal_budgets ? { withdrawal_budgets } : {}),
        ...(withdrawal_description ? { withdrawal_description } : {}),
        ...(deposit_account_id ? { deposit_account_id } : {}),
        ...(deposit_budgets ? { deposit_budgets } : {}),
        ...(deposit_description ? { deposit_description } : {}),
        ...(record_date ? { record_date: new Date(record_date).toISOString() } : {}),
        ...(amount ? { amount: parseFloat(amount) } : {}),
      };

      const response = await axios.post(`/api/transfers`, payload);
      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const updateTransfer = createAsyncThunk(
  'transfer/updateTransfer',
  async (
    { id, withdrawal_description, deposit_description, record_date },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const payload = {
        ...(withdrawal_description ? { withdrawal_description } : {}),
        ...(deposit_description ? { deposit_description } : {}),
        ...(record_date ? { record_date: new Date(record_date).toISOString() } : {}),
      };

      const response = await axios.put(`/api/transfers/${id}`, payload);
      if (!response.data || !response.data.transfer) {
        return rejectWithValue({ error: 'Unable to update your row' });
      }

      return response.data;
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

export const deleteOneRow = createAsyncThunk(
  'transfer/deleteOneRow',
  async ({ id }, { rejectWithValue, dispatch, getState }) => {
    try {
      const transferToDelete = getState().transfer.allRows.data.find(r => r.id === id);
      await axios.delete(`/api/transfers/${id}`);
      return { transfer: { ...transferToDelete } };
    } catch (err) {
      if ((err.response || {}).status === 401) {
        dispatch(logout());
      }

      if (((err.response || {}).data || {}).error) {
        const errorKey = err.response.data.error.key;
        const errorMsg = err.response.data.error.message;
        return rejectWithValue({ error: errorMsg, name });
      }

      return rejectWithValue({ error: 'An unexpected error occured' });
    }
  }
);

function canTransferBeDisplayed(transfer, searchParams) {
  const transferDate = new Date(transfer.record_date);
  const transferMonth = transferDate.getMonth() + 1;
  const transferYear = transferDate.getFullYear();
  if (!!searchParams.account_id) {
    const transferAccounts = [transfer.withdrawal_account_id, transfer.deposit_account_id].filter(Boolean);
    if (!transferAccounts.includes(searchParams.account_id)) {
      return false;
    }
  }

  if (!!searchParams.budgets && !!searchParams.budgets.length) {
    const transferBudgets = [].concat(transfer.withdrawal_budgets).concat(transfer.deposit_budgets).filter(Boolean);
    const budgetsOnSearchAndTransfer = transferBudgets.filter(b => searchParams.budgets.includes(b));
    if (!budgetsOnSearchAndTransfer.length) {
      return false;
    }
  }

  if (!!searchParams.month && searchParams.month !== transferMonth) {
    return false;
  }

  if (!!searchParams.year && searchParams.year !== transferYear) {
    return false;
  }

  return true;
}

const transferInitialState = {
  searchParams: {
    account_id: null,
    budget_id: null,
    budgets: null,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  },
  allRows: {
    data: [],
    loading: false,
    done: false,
    error: null,
  },
  addOne: {
    loading: false,
    done: false,
    error: null,
  },
  updateOne: {
    loading: false,
    done: false,
    error: null,
  },
  deleteOne: {
    loading: false,
    done: false,
    error: null,
  },
};

export const transferSlice = createSlice({
  name: 'transfer',
  initialState: { ...transferInitialState },
  reducers: {
    clearAllTransferState: (store) => {
      return { ...transferInitialState };
    },
    clearSearchParams: (store) => {
      return {
        ...store,
        searchParams: {
          ...transferInitialState.searchParams,
        },
      };
    },
    updateCurrentSearchParams: (store, action) => {
      return {
        ...store,
        searchParams: {
          ...action.payload,
        },
      };
    },
    clearDeleteOneState: (state) => {
      return {
        ...state,
        deleteOne: {
          ...transferInitialState.deleteOne,
        },
      };
    },
    clearUpdateOneState: (state) => {
      return {
        ...state,
        updateOne: {
          ...transferInitialState.updateOne,
        },
      };
    },
    clearCreateOneState: (state) => {
      return {
        ...state,
        addOne: {
          ...transferInitialState.addOne,
        },
      };
    },
    clearAllRowsState: (state) => {
      return {
        ...state,
        allRows: {
          ...transferInitialState.allRows,
        },
      };
    },
    resetRowsAfterAddState: (state) => {
      return {
        ...state,
        allRows: {
          ...transferInitialState.allRows,
        },
        createOne: {
          ...transferInitialState.createOne,
        }
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        loading: return {
          ...state,
          allRows: {
            ...transferInitialState.allRows,
            loading: true,
          },
        };
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        const { transfers } = action.payload;
        return {
          ...state,
          allRows: {
            ...transferInitialState.allRows,
            done: true,
            data: transfers,
          },
        };
      })
      .addCase(fetchRows.rejected, (state, action) => {
        const { error } = action.payload;
        return {
          ...state,
          allRows: {
            ...transferInitialState.allRows,
            done: true,
            loading: false,
            error,
            data: [],
          },
        };
      })
      .addCase(deleteOneRow.pending, (state) => {
        loading: return {
          ...state,
          deleteOne: {
            ...transferInitialState.deleteOne,
            loading: true,
          },
        };
      })
      .addCase(deleteOneRow.fulfilled, (state, action) => {
        const { transfer } = action.payload;
        return {
          ...state,
          deleteOne: {
            ...transferInitialState.deleteOne,
            done: true,
          },
          allRows: {
            ...state.allRows,
            data: state.allRows.data.filter((r) => r.id !== transfer.id),
          },
        };
      })
      .addCase(deleteOneRow.rejected, (state, action) => {
        return {
          ...state,
          deleteOne: {
            ...transferInitialState.deleteOne,
            done: true,
            error: action.payload.error,
          },
        };
      })
      .addCase(updateTransfer.pending, (state) => {
        loading: return {
          ...state,
          updateOne: {
            ...transferInitialState.updateOne,
            loading: true,
          },
        };
      })
      .addCase(updateTransfer.fulfilled, (state, action) => {
        const { transfer } = action.payload;

        const transferCanBeDisplayed = canTransferBeDisplayed(transfer, state.searchParams);

        return {
          ...state,
          updateOne: {
            ...transferInitialState.updateOne,
            done: true,
          },
          allRows: {
            ...state.allRows,
            data: state.allRows.data
              .map((r) => r.id === transfer.id
                ? (transferCanBeDisplayed ? transfer : null)
                : r
              )
              .filter(Boolean),
          },
        };
      })
      .addCase(updateTransfer.rejected, (state, action) => {
        return {
          ...state,
          updateOne: {
            ...transferInitialState.updateOne,
            done: true,
            error: action.payload.error,
          },
        };
      })
      .addCase(createTransfer.pending, (state) => {
        loading: return {
          ...state,
          addOne: {
            ...transferInitialState.addOne,
            loading: true,
          },
        };
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        const { transfer } = action.payload;

        const transferCanBeDisplayed = canTransferBeDisplayed(transfer, state.searchParams);

        return {
          ...state,
          allRows: {
            ...state.allRows,
            data: !!transferCanBeDisplayed
              ? state.allRows.data.concat(transfer)
              : state.allRows.data,
          },
          addOne: {
            ...transferInitialState.addOne,
            done: true,
          },
        };
      })
      .addCase(createTransfer.rejected, (state, action) => {
        return {
          ...state,
          addOne: {
            ...transferInitialState.addOne,
            done: true,
            error: action.payload.error,
          },
        };
      })
      .addCase(logout.fulfilled, () => {
        return { ...transferInitialState };
      });
  },
});

export const {
  updateCurrentSearchParams,
  clearCreateOneState,
  clearAllRowsState,
  resetRowsAfterAddState,
  clearSearchParams,
  clearUpdateOneState,
  clearDeleteOneState,
  clearAllTransferState,
} = transferSlice.actions;

export default transferSlice.reducer;
